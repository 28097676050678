import React from 'react';
import { graphql } from 'gatsby';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import SectionRenderer from '../components/SectionRenderer/SectionRenderer';
import Section from '../components/Section/Section';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import { MasonryGrid, MasonryGridItem } from '../components/UI/MasonryGrid/MasonryGrid';
import Filter from '../components/UI/Filter/Filter';
import WorkItem from '../components/WorkItem/WorkItem';
import Fade from 'react-reveal/Fade';
import useFilter from '../hooks/useFilter';
import parseMarkdown from '../utils/parseMarkdown';

export const WorkPageTemplate = ({
    name,
    title,
    items,
    taglist,
    sections
}) => {
    const [ currentFilter, setCurrentFilter ] = useFilter();
    const filteredItems = items.filter( item => {
        return currentFilter ? !!item.node.frontmatter.tags.find( tag => {
            return tag.value === currentFilter;
        }) : item;
    });

    function handleFilterChange( value ) {
        setCurrentFilter( value );
    }

    return (
        <>
            <Section Tag="div" theme={themes.LIGHT} isCompact={true}>
                <Fade bottom>
                    <Hgroup lead={name} title={title}/>
                    <Filter label="Show me " items={taglist} onChange={handleFilterChange} selected={currentFilter} />
                </Fade>

                <MasonryGrid>
                    {filteredItems.map( ({node}) => {
                        return (
                            <MasonryGridItem key={node.fields.slug}>
                                <WorkItem {...node.frontmatter} slug={node.fields.slug} />
                            </MasonryGridItem>
                        )
                    })}
                </MasonryGrid>
            </Section>

            <SectionRenderer data={parseMarkdown(sections)} />
        </>
    )
};

const WorkPage = ({ data: { page, items, tags } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.name || false}
            theme={themes.LIGHT}
        >
            <WorkPageTemplate {...parseMarkdown(page)} items={items.edges} taglist={tags.frontmatter.taglist} />
        </Layout>
    )
};

export default WorkPage;

export const pageQuery = graphql`
  ## Query for WorkPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query WorkPageTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
        ...Meta
        ...Sections
        frontmatter {
            name
            title
        }
    }
    items: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
            frontmatter: {
                template: { eq: "WorkItem" }
                isPublished: { ne: false }
            }
        }
    ) {
        edges {
            node {
                fields {
                    slug
                }
                frontmatter {
                    name
                    title
                    image {
                        id
                        src {
                            publicURL
                            extension
                        }
                        fit
                        position
                    }
                    tags {
                        value
                        label
                    }
                }
            }
        }
     }
     tags: markdownRemark(frontmatter: {id: {eq: "settings-tags"}}) {
        frontmatter {
            taglist {
                id
                name
            }
        }
     }
  }
`
