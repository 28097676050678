import React from 'react';
import Icon from '../Icon/Icon.js';
import styles from './Filter.module.scss';

const Filter = ({ label, placeholder, items, selected, onChange }) => {   
    function handleFilterChange(e) {
        onChange( e.target.value );
    }

    return (
        <p className={styles.filter}>
            <label htmlFor="filter">{label}</label>

            <span className={styles.select}>
                <select id="filter" className={styles.selectList} onChange={handleFilterChange} value={selected || ''} >
                    <option value="">{placeholder}</option>
                    {items.map(item =>
                        <option value={item.id} key={item.id}>{item.name}</option>
                    )}
                </select>
                <span className={styles.selectValue}>
                    { selected ? items.find( item => item.id === selected ).name : placeholder }
                </span>
            </span>

            <Icon name="chevron" className={styles.icon}/>
        </p>
    )
};

Filter.defaultProps = {
    label: 'Show me ',
    placeholder: 'Everything'
};

Filter.propTypes = {

};

export default Filter;
