import { useLayoutEffect, useState, useRef } from 'react';

function getParams(){
    return typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
}

function useFilter() {
    const params = getParams();
    const firstUpdate = useRef(true);
    const [currentFilter, setCurrentFilter] = useState( params ? params.get('filter') : '' );

    useLayoutEffect(() => {
        if ( firstUpdate.current ) {
            firstUpdate.current = false;
            return;
          }

        if( currentFilter ) {
            params.set('filter', currentFilter);
        } else {
            params.delete('filter');
        }

        window.history.replaceState({}, '', `${window.location.pathname}${currentFilter ? `?${params}` : ``}`);
    }, [ currentFilter ]);

    return [currentFilter, setCurrentFilter];
}

export default useFilter;